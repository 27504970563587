body {
  margin: 0;
  font-family: 'Inter';font-size: 58px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter';font-size: 58px;
}

html {
  height: 100%;
  overflow: hidden;
}

:root{
  --leaflet-width: 100%;
  --show-content: hidden;
  --show-loading: flex;
  --leaflet-height: 100vh;
  --leaflet-offset: translate(0px);
  overflow: hidden;
  --close-button-size: 30px;
  --side-mobile-header-size: 50px;
  --sidebar-text: rgb(29, 29, 29);
  --sidebar-transition: 0.3s cubic-bezier(.77,.04,.18,1.03);
  --sidebar-transition-verical: 0.1s cubic-bezier(.77,.04,.18,1.03);
  --leaflet-cont-transition: 0.35s cubic-bezier(.77,.04,.18,1.03);
  --sidebar-text-size: 16px;
  --stroke-weight: 1px;
  --dark: rgb(23, 23, 23);
  --light: rgb(255, 255, 255);
}

 /* width */
 ::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--light);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000;
} 

::selection {
  background: #ff3300; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #ff3300; /* Gecko Browsers */
}

.sidebar-rest{
  cursor: pointer;
  flex-shrink: inherit;
  position: fixed;
  z-index: 10;
  top: 100px;
  left: 0%;
  display: flex;
  flex-direction: row;
  justify-content: flex;
  align-items: center;
  box-sizing:border-box;
  height: -webkit-calc(100% - 100px);
  height: -moz-calc(100% - 100px);
  height: calc(100% - 100px);
  width: 100px;
  border-right: var(--stroke-weight) solid var(--sidebar-text);;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: var(--light);
  color: var(--dark);
}

.sidebar-rest:hover{
  background-color: var(--dark);
  color: var(--light);
}

.sidebar-arrow{
  height: 20px;
  width: 20px;
}

.sidebar-rest-text{
  rotate: -90deg;
  font-family: 'Inter';font-size: 16px;
}

.sidebar-line{
  z-index: 0;
  position: fixed;
  left: calc(var(--close-button-size) - 12px);
  height: 100%;
  width: var(--stroke-weight);
  background-color: var(--sidebar-text);
}

.sidebar{
  position: absolute;
  z-index: 1000;
  top: 100px;
  left: 0%;
  display: flex;
  flex-direction: column;
  justify-content: flex;
  box-sizing:border-box; 
  width: 50%;
  height: -webkit-calc(100% - 100px);
  height: -moz-calc(100% - 100px);
  height: calc(100% - 100px);
  border-right: var(--stroke-weight) solid var(--sidebar-text);; 
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: var(--light);
  color: var(--sidebar-text);
  transition: left var(--sidebar-transition), opacity 0.1s ease-in ;
}

.sidebar-collapsed{
  position: absolute;
  top: 100px;
  left: -50%;
  display: flex;
  flex-direction: column;
  justify-content: flex;
  box-sizing:border-box; 
  width: 50%;
  height: -webkit-calc(100% - 100px);
  height: -moz-calc(100% - 100px);
  height: calc(100% - 100px);;
  border-right: var(--stroke-weight) solid var(--sidebar-text);; 
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: var(--light);
  color: var(--sidebar-text);
  transition: left var(--sidebar-transition), opacity 0.5s  ease-in ;
}

.select-icon{
  height: 15px;
  width: 15px;
}
.img-style{
  width: 100%;
  box-sizing:border-box; 
  border-top: var(--stroke-weight) solid var(--sidebar-text);
}

.sidebar-header{
  text-align: left;
  position: fixed;
  text-overflow: ellipsis;
  word-wrap: break-word;
  width: 50%;
  background-color: var(--light);
  box-sizing:border-box; 
  border-right: var(--stroke-weight) solid var(--sidebar-text);
}
.sidebar-heading{
  height: 50px;
  width: 100%;
  display: flex;
  font-family: 'Inter';font-size: var(--sidebar-text-size);
  justify-content: space-between;
  align-items: center;
  box-sizing:border-box; 
  border-bottom: var(--stroke-weight) solid var(--sidebar-text);
}
.sidebar-header-text{
  height: 50px;
  width: 100%;
  padding-left: 6px;
  display: flex;
  font-family: 'Inter';font-size: var(--sidebar-text-size);
  justify-content: space-between;
  align-items: center;
  box-sizing:border-box; 
}
.sidebar-header-text:hover{
  cursor: pointer;
  height: 50px;
  width: 100%;
  display: flex;
  font-family: 'Inter';font-size: var(--sidebar-text-size);
  justify-content: first start;
  align-items: center;
  color: var(--light);
  background: var(--dark);
  box-sizing:border-box; 
}
.sidebar-header-buttons{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing:border-box; 
}
.close-button-container{
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing:border-box; 
  border-left: var(--stroke-weight) solid var(--sidebar-text);
}

.close-button-container:hover{
  cursor: pointer;
  color: var(--light);
  background: var(--dark);
  box-sizing:border-box; 
  border-left: var(--stroke-weight) solid var(--sidebar-text);
}

.close-button{
  width: calc(var(--close-button-size) - 8px);
  height: calc(var(--close-button-size) - 8px);
}

.search-button-container{
  width: 38px;
}

.google-button{
  width: calc(var(--close-button-size) - 3px);
  height: calc(var(--close-button-size) - 3px);
  box-sizing:border-box; 
}

.sidebar-header-title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content-container{
  visibility: var(--show-content);
  display: flex;
  flex-direction: column;
}



.post-info{
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-top: var(--stroke-weight) solid var(--sidebar-text);
  border-bottom: var(--stroke-weight) solid var(--sidebar-text);
}

.post-date{
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.post-date-disable{
  display: none;
  flex-direction: row;
  height: 50px;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-right: var(--stroke-weight) solid var(--sidebar-text);
}

.post-link{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  color: var(--light);
  box-sizing: border-box;
  border-left: var(--stroke-weight) solid var(--sidebar-text);
  border-top: var(--stroke-weight) solid var(--sidebar-text);
  border-bottom: var(--stroke-weight) solid var(--sidebar-text);
}

.post-link-disabled-date{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  color: var(--light);
  box-sizing: border-box;
  border-top: var(--stroke-weight) solid var(--sidebar-text);
  border-bottom: var(--stroke-weight) solid var(--sidebar-text);

}
.post-button-container{
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.post-button{
  width: calc(var(--close-button-size) - 3px);
  height: calc(var(--close-button-size) - 3px);
}

.post-text{
  padding: 5px;
  padding-bottom: 25px;
  font-family: 'Inter';font-size: var(--sidebar-text-size);
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}



.post-text-disabled{
  display: none;
}

.sidebar-space{
  display: block;
  position: relative;
  height: 100px;
}

.tags-container{
  display: flex;
  flex-direction: row;
  
}

.tags{
  display: flex;
  justify-content: center;
}

.basic-text{
  padding-left: 5px;
  font-family: 'Inter';font-size: var(--sidebar-text-size);
}

.underlined-text{
  padding-left: 5px;
  font-family: 'Inter';font-size: var(--sidebar-text-size);
  text-decoration: underline;
}

.basic-heading{
  font-family: 'Inter';font-size: 58px;
}

.big-heading{
  font-size: 60px;
  font-weight: bold;
  font-family: 'Inter';font-size: 58px;
  width: 80%;
}



.loading-screen{
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
  font-family: 'Inter';font-size: 24px;
}

.loading-container{
  position: fixed;
  height: calc(100% - 100px);
  width: 50%;
  display: var(--show-loading);
  justify-content: center;
  align-items: center;
  gap: 5px;
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
  font-family: 'Inter';font-size: 16px;
}

.loading-icon{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--dark);
}
.loading-icon-sidebar{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: var(--light);
  border: var(--stroke-weight) solid var(--dark);
}
.load-text{
  font-family: 'Inter';font-size: 58px;
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}

.header{
  position: absolute;
  z-index: 1000;
  height: 100px;
  width: 100%;
  display: flex;
  font-family: 'Inter';font-size: 58px;
  justify-content: space-between;
  align-items: center;
  box-sizing:border-box; 
  padding-left: 5px;
  background-color: var(--light);
}

.header-text-container{
  height: 100px;
  display: flex;
  font-family: 'Inter';font-size: 58px;
  justify-content: flex-start;
  align-items: center;
  box-sizing:border-box; 
  gap: 10px;
  background-color: var(--light);
}

.header-text-small{
  display: inline-block;
  font: 'Inter'; font-size: 30px;
}

.header-text-small-small{
  display: none;
  font: 'Inter'; font-size: 26px;
}
.header-links{
  padding-top: 12px;
  height: 100px;
  display: flex;
  font-family: 'Inter';font-size: 58px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  box-sizing:border-box; 
  gap: 18px;
  background-color: var(--light);
}

.header-links-small{
  height: 100px;
  display: none;
  font-family: 'Inter';font-size: 58px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  box-sizing:border-box; 
  gap: 18px;
  background-color: var(--light);
}

.header-link{
  display: inline-block;
  font: 'Inter'; font-size: 16px;
  
}
.header-link:hover{
  cursor: pointer;
}

.line {
  position: absolute;
  z-index: 1500;
  top: 99px;
  width: 100%;
  height: var(--stroke-weight);
  background-color: var(--dark);
  transform-origin: center top;
  transition: width var(--sidebar-transition), opacity 0.1s ease-in ;
  
}
.line.active {
  width: 100%;
  transition: width var(--sidebar-transition), opacity 0.1s ease-in ;
}

.line2 {
  position: absolute;
  z-index: 1500;
  width: var(--stroke-weight);
  height: 0;
  left: calc(50% - 2px);
  background-color: var(--dark);
  transform-origin: center top;
  transition: height var(--sidebar-transition-verical), opacity 0.05s ease-in ;
  
}
.line2.active {
  height: 100%;
  transition: height var(--sidebar-transition-verical), opacity 0.05s ease-in ;
}

.header-icon{
  height: 100px;
  width: 100px;
  background-color: var(--dark);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing:border-box;
  border: var(--stroke-weight) solid var(--dark);
}




.leaflet-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1;
}

.leaflet-container-moved {
  left: 2000px;
}

.expand-button{
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 900;
  width: 45px;
  height: 45px;
  padding: 5px;
  background: var(--dark);
  color: var(--light);
}

.expand-button:hover{
  cursor: pointer;
  background: #ff1900;
  color: var(--light);
}

.sidebar-loading{
  width: 100%;
}

@media only screen and (max-width: 1000px) {

  .loading-container{
    position: fixed;
    height: 100%;
    width: 100%;
    display: var(--show-loading);
    justify-content: center;
    align-items: center;
    -webkit-animation: flickerAnimation 1s infinite;
    -moz-animation: flickerAnimation 1s infinite;
    -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
  }
  @keyframes sidebar-opening {
    0%   {left: 0%; top: 100vh;}
    50%  {left: 0%; top: 100vh}
    100% {left: 0%; top: 100px}
  }
.sidebar{
  position: absolute;
  top: 100px;
  left: 0%;
  display: flex;
  flex-direction: column;
  justify-content: flex;
  box-sizing:border-box; 
  width: 100%;
  height: -webkit-calc(100% - 100px);
  height: -moz-calc(100% - 100px);
  height: calc(100% - 100px);
  border-right: 0px solid var(--sidebar-text);; 
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: var(--light);
  color: var(--sidebar-text);
  animation-name: sidebar-opening;
  animation-duration: 0.3s;
  animation-timing-function: var(--sidebar-transition);
}

.sidebar-collapsed{
  position: absolute;
  top: 100vh;
  left: -50%;
  display: none;
  flex-direction: column;
  justify-content: flex;
  box-sizing:border-box; 
  width: 100%;
  height: -webkit-calc(100% - 100px);
  height: -moz-calc(100% - 100px);
  height: calc(100% - 100px);
  border-right: var(--stroke-weight) solid var(--sidebar-text);; 
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: var(--light);
  color: var(--sidebar-text);
}

  .header{
  position: fixed;
  z-index: 1000;
  height: 100px;
  width: 100%;
  display: flex;
  font-family: 'Inter';font-size: var(--sidebar-text-size);
  justify-content: space-between;
  align-items: center;
  box-sizing:border-box; 
  padding-left: 2px;
  background-color: var(--light);
  }

  .line.active {
    width: 100%;
    transition: width var(--sidebar-transition), opacity 0.1s ease-in ;
  }

  .header-text-small{
    display: none;
    font: 'Inter'; font-size: 30px;
  }
  
  .header-text-small-small{
    display: inline-block;
    font: 'Inter'; font-size: 26px;
  }

  .header-links{
    padding-top: 12px;
    height: 100px;
    display: none;
    font-family: 'Inter';font-size: 58px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    box-sizing:border-box; 
    gap: 18px;
    background-color: var(--light);
  }


.header-links-small{
  position: fixed;
  padding-right: 5px;
  padding-left: 5px;
  z-index: 1000;
  top: 100px;
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing:border-box; 
  gap: 18px;
  background-color: var(--light);
}
  
  

  .sidebar-heading{
    height: var(--side-mobile-header-size);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing:border-box; 
    font-size: 20px;
    border-bottom: var(--stroke-weight) solid var(--sidebar-text);
    padding-left: 0px;
    transition: var(--sidebar-transition);
  }

  .sidebar-header{
    text-align: left;
    position:fixed;
    text-overflow: ellipsis;
    word-wrap: break-word;
    width: 100%;
    background-color: var(--light);
    box-sizing:border-box; 
    border-right: 0px solid var(--sidebar-text);
    transition: var(--sidebar-transition);
  }

 .line{
  width: 0%;
 }

 .sidebar-rest{
  top: calc(100% - 20px);
  height: 20px;
  width: 100%;
  border: 0;
  flex-direction: column;
  background-color: var(--dark);
  color: var(--light);
 }

 .sidebar-rest-text{
  display: none;
 }

 .sidebar-arrow{
  height: 20px;
  width: 20px;
  rotate: -90deg;
 }

 .close-button{
  rotate: -90deg;
 }

}